import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@clerk+nextjs@5.5.2_next@14.2.3_@babel+core@7.23.7_react-dom@18.2.0_react@18.2.0__react@18.2._nphoevpl62sfipmupdya5bneem/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@clerk+nextjs@5.5.2_next@14.2.3_@babel+core@7.23.7_react-dom@18.2.0_react@18.2.0__react@18.2._nphoevpl62sfipmupdya5bneem/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["useAuth","useClerk","useEmailLink","useOrganization","useOrganizationList","useSession","useSessionList","useSignIn","useSignUp","useUser"] */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@clerk+nextjs@5.5.2_next@14.2.3_@babel+core@7.23.7_react-dom@18.2.0_react@18.2.0__react@18.2._nphoevpl62sfipmupdya5bneem/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","GoogleOneTap","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile","__experimental_UserVerification"] */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@clerk+nextjs@5.5.2_next@14.2.3_@babel+core@7.23.7_react-dom@18.2.0_react@18.2.0__react@18.2._nphoevpl62sfipmupdya5bneem/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/accessible-icon.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/alert-dialog.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/aspect-ratio.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/avatar.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/callout.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/checkbox-cards.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/checkbox-group.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/checkbox.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/context-menu.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/dialog.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/dropdown-menu.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/hover-card.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/popover.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/portal.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/progress.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/radio-cards.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/radio-group.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/radio.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/scroll-area.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/segmented-control.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/select.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/separator.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/slider.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/switch.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/tab-nav.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/tabs.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/text-field.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/theme-panel.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/theme.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/tooltip.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/dist/esm/components/visually-hidden.js");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/next@14.2.3_@babel+core@7.23.7_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/packages/web/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/react-grid-layout@1.4.4_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-grid-layout/css/styles.css");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/react-resizable@3.0.5_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-resizable/css/styles.css");
;
import(/* webpackMode: "eager" */ "/Users/changp1/Documents/2024/meddocks/node_modules/.pnpm/@radix-ui+themes@3.0.2_@types+react-dom@18.2.18_@types+react@18.2.48_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/@radix-ui/themes/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["ConfigContextClient"] */ "/Users/changp1/Documents/2024/meddocks/packages/web/src/components/config-context/config-context-client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/Users/changp1/Documents/2024/meddocks/packages/web/src/registry/new-york/ui/toaster.tsx");
